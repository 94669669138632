<template>
  <el-container direction="vertical">
    <el-header height="40px">
      <el-container direction="horizontal">
        <span style="font-size: 24px;margin: 0px auto;text-align: center">陈仓初中聊天群机器人漂流瓶管理系统</span>
      </el-container>
    </el-header>
  </el-container>
  <router-view />
  <el-footer>
    <div style="height: 40px"></div>
    <span>Copyright © 2022 maohaoji.com</span>
  </el-footer>

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

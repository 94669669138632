
import { defineComponent } from 'vue';
import Storage from '../Storage'
import { ElMessage } from "element-plus";
import { v4 } from 'uuid'

const api = '/api' /*'http://api.bot.czqiang.com:8686'*/
export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      state: {
        menu_index_open: '1-1',
        SlsectBottleNumberButton: true,
        SlsectBottleButton: true,
        SlsectBottleResult: false,
        SlsectBottleResult_1_3: false,
        SlsectBottleButton_1_3: true,
        RemoveBottleButton_1_4: true,
        AddBottleButton_1_5: true,
        Result_1_5: false
      },
      temp: {
        bottle_number: 0,
        bottle_id: '',
        bottle: [{ info: { QQid: 0, id: '', timeout: '', stat: '' }, data: {} }],
        bottle_id_1_3: '',
        bottle_1_3: { info: { QQid: 0, id: '', timeout: '', stat: 0, time_Date_object: new Date() }, data: {} },
        bottle_stat_select: [
          {
            value: 1,
            label: '已被捡拾'
          },
          {
            value: 0,
            label: '未被捡拾'
          }
        ],
        bottle_id_1_4: '',
        bottle_1_5: { info: { QQid: '', id: '', timeout: '', stat: 0, time_Date_object: new Date() }, data: {} },
      }
    }
  },
  methods: {
    selectBottleNumber() {
      this.state.SlsectBottleNumberButton = false
      this.axios({
        method: 'post',
        url: `${api}/GetBottleNumber`,
        data: {
          token: Storage.state.token
        }
      }).then((res: any) => {
        res = res.data
        switch (res.code) {
          case 201:
            ElMessage({
              message: `获取错误，请稍后重试`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleNumberButton = true
            break;
          case 202:
            ElMessage({
              message: `获取错误，请稍后重试。${res.data.error}`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleNumberButton = true
            break;
          case 400:
          case 401:
          case 203:

            ElMessage({
              message: `登录过期，请重新登录`,
              type: 'error',
              duration: 3000
            })
            setTimeout(() => {
              this.$cookies.remove('token')
              this.$router.push({ name: 'login' })
            }, 3000)
            this.state.SlsectBottleNumberButton = true
            break;
          case 500:
            ElMessage({
              message: `数据库错误`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleNumberButton = true
            break
          case 200:
            this.temp.bottle_number = res.data
            this.state.SlsectBottleNumberButton = true
        }
      })
    },
    SlsectBottle() {
      this.state.SlsectBottleButton = false
      this.axios({
        method: 'post',
        url: `${api}/SelectBottle`,
        data: {
          token: Storage.state.token,
          id: this.temp.bottle_id
        }
      }).then((res: any) => {
        res = res.data
        switch (res.code) {
          case 201:
            ElMessage({
              message: `获取错误，请稍后重试`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleButton = true
            break;
          case 202:
            ElMessage({
              message: `获取错误，请稍后重试。${res.data.error}`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleButton = true
            break;
          case 400:
          case 401:
          case 203:
            ElMessage({
              message: `登录过期，请重新登录`,
              type: 'error',
              duration: 3000
            })
            setTimeout(() => {
              this.$cookies.remove('token')
              this.$router.push({ name: 'login' })
            }, 3000)
            this.state.SlsectBottleButton = true
            break;
          case 500:
            ElMessage({
              message: `数据库错误`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleButton = true
            break
          case 200:
            if (res.data.length <= 0) {
              ElMessage({
                message: `没有找到任何数据`,
                type: 'error',
                duration: 3000
              })
            } else {
              this.temp.bottle = []

              for (let i = 0; i < res.data.length; i++) {
                this.temp.bottle[this.temp.bottle.length] = {
                  info: {
                    QQid: res.data[i].QQid,
                    id: res.data[i].id,
                    timeout: String(ts2time(Number(res.data[i].timeout))),
                    stat: (Number(res.data[i].stat) == 1) ? `已被捡拾` : `未被捡拾`
                  },
                  data: JSON.parse(res.data[i].msg)
                }
              }
              this.state.SlsectBottleResult = true
            }
            this.state.SlsectBottleButton = true
        }
      })
    },
    logout() {
      this.$cookies.remove('token')
      this.$router.push({ name: 'login' })
    },
    SlsectBottle_1_3() {
      this.state.SlsectBottleButton_1_3 = false
      this.axios({
        method: 'post',
        url: `${api}/SelectBottle`,
        data: {
          token: Storage.state.token,
          id: this.temp.bottle_id_1_3
        }
      }).then((res: any) => {
        res = res.data
        switch (res.code) {
          case 201:
            ElMessage({
              message: `获取错误，请稍后重试`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleButton_1_3 = true
            break;
          case 202:
            ElMessage({
              message: `获取错误，请稍后重试。${res.data.error}`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleButton_1_3 = true
            break;
          case 400:
          case 401:
          case 203:
            ElMessage({
              message: `登录过期，请重新登录`,
              type: 'error',
              duration: 3000
            })
            setTimeout(() => {
              this.$cookies.remove('token')
              this.$router.push({ name: 'login' })
            }, 3000)
            this.state.SlsectBottleButton_1_3 = true
            break;
          case 500:
            ElMessage({
              message: `数据库错误`,
              type: 'error',
              duration: 3000
            })
            this.state.SlsectBottleButton_1_3 = true
            break
          case 200:
            if (res.data.length <= 0) {
              ElMessage({
                message: `没有找到任何数据`,
                type: 'error',
                duration: 3000
              })
            } else {
              this.temp.bottle_1_3 = {
                info: {
                  QQid: res.data[0].QQid,
                  id: res.data[0].id,
                  timeout: String(ts2time(Number(res.data[0].timeout))),
                  stat: res.data[0].stat,
                  time_Date_object: new Date(Number(res.data[0].timeout))
                },
                data: JSON.parse(res.data[0].msg)
              }

              this.state.SlsectBottleResult_1_3 = true
            }
            this.state.SlsectBottleButton_1_3 = true
        }
      })
    },
    change_bottle() {
      this.state.SlsectBottleButton_1_3 = false
      if ((this.temp.bottle_1_3.data as any).text == undefined || (this.temp.bottle_1_3.data as any).text == '' || this.temp.bottle_1_3.info.QQid != Number(this.temp.bottle_1_3.info.QQid) || this.temp.bottle_1_3.info.QQid <= 0 || this.temp.bottle_1_3.info.time_Date_object.getTime() <= 0) {
        ElMessage({
          message: `请正确输入内容，确保存在文字内容、正确的QQ已经1970年以后的日期`,
          type: 'error',
          duration: 3000
        })
      } else {
        let new_bottle: {
          QQid: number,
          id: string,
          msg: {
            text: string,
            img: string[] | [],
          },
          stat: number,
          timeout: number
        } = {
          QQid: this.temp.bottle_1_3.info.QQid,
          id: this.temp.bottle_1_3.info.id,
          msg: {
            text: (this.temp.bottle_1_3.data as any).text,
            img: ((this.temp.bottle_1_3.data as any).img == undefined || (this.temp.bottle_1_3.data as any).img.length == 0) ? [] : (this.temp.bottle_1_3.data as any).img,
          },
          stat: this.temp.bottle_1_3.info.stat,
          timeout: this.temp.bottle_1_3.info.time_Date_object.getTime()
        }
        this.axios({
          method: 'post',
          url: `${api}/modiftBottle`,
          data: {
            token: Storage.state.token,
            QQid: new_bottle.QQid,
            id: new_bottle.id,
            msg: JSON.stringify(new_bottle.msg),
            stat: new_bottle.stat,
            timeout: new_bottle.timeout
          }
        }).then((res: any) => {
          res = res.data
          switch (res.code) {
            case 201:
              ElMessage({
                message: `修改错误，请稍后重试`,
                type: 'error',
                duration: 3000
              })

              this.state.SlsectBottleButton_1_3 = true
              break;
            case 202:
              ElMessage({
                message: `修改错误，请稍后重试。${res.data.error}`,
                type: 'error',
                duration: 3000
              })
              this.state.SlsectBottleButton_1_3 = true
              break;
            case 400:
            case 401:
            case 203:

              ElMessage({
                message: `登录过期，请重新登录`,
                type: 'error',
                duration: 3000
              })
              setTimeout(() => {
                this.$cookies.remove('token')
                this.$router.push({ name: 'login' })
              }, 3000)
              this.state.SlsectBottleButton_1_3 = true
              break;
            case 500:
              ElMessage({
                message: `数据库错误`,
                type: 'error',
                duration: 3000
              })
              this.state.SlsectBottleButton_1_3 = true
              break
            case 200:
              ElMessage({
                message: `修改成功`,
                type: 'success',
                duration: 3000
              })
              this.state.SlsectBottleButton_1_3 = true
          }
        })
      }
    },
    RemoveBottle() {
      this.state.RemoveBottleButton_1_4 = false
      if (this.temp.bottle_id_1_4.length == 0) {
        ElMessage({
          message: `请输入漂流瓶编号`,
          type: 'error',
          duration: 3000
        })
        this.state.RemoveBottleButton_1_4 = true
      } else {
        this.axios({
          method: 'post',
          url: `${api}/delBottle`,
          data: {
            token: Storage.state.token,
            id: this.temp.bottle_id_1_4
          }
        }).then((res: any) => {
          res = res.data
          switch (res.code) {
            case 201:
              ElMessage({
                message: `删除错误，请稍后重试`,
                type: 'error',
                duration: 3000
              })

              this.state.RemoveBottleButton_1_4 = true
              break;
            case 202:
              ElMessage({
                message: `删除错误，请稍后重试。${res.data.error}`,
                type: 'error',
                duration: 3000
              })
              this.state.RemoveBottleButton_1_4 = true
              break;
            case 400:
            case 401:
            case 203:

              ElMessage({
                message: `登录过期，请重新登录`,
                type: 'error',
                duration: 3000
              })
              setTimeout(() => {
                this.$cookies.remove('token')
                this.$router.push({ name: 'login' })
              }, 3000)
              this.state.RemoveBottleButton_1_4 = true
              break;
            case 500:
              ElMessage({
                message: `数据库错误`,
                type: 'error',
                duration: 3000
              })
              this.state.RemoveBottleButton_1_4 = true
              break
            case 200:
              ElMessage({
                message: `删除成功`,
                type: 'success',
                duration: 3000
              })
              this.state.RemoveBottleButton_1_4 = true
          }
        })
      }
    },
    AddBottle() {
      this.state.AddBottleButton_1_5 = false
      if ((this.temp.bottle_1_5.data as any).text == undefined || (this.temp.bottle_1_5.data as any).text.length == 0 || (this.temp.bottle_1_5.info.QQid as unknown as number) != Number(this.temp.bottle_1_5.info.QQid) || this.temp.bottle_1_5.info.time_Date_object.getTime() <= 0) {
        ElMessage({
          message: `请输入正确的内容，确保有文字内容，1970年以后的发布日期以及正确的QQ账号`,
          duration: 3000,
          type: 'error'
        })
        this.state.AddBottleButton_1_5 = true
      } else {
        this.temp.bottle_1_5.info.id = v4()
        let new_bottle: {
          QQid: number,
          id: string,
          msg: {
            text: string,
            img: string[] | [],
          },
          stat: number,
          timeout: number
        } = {
          QQid: Number(this.temp.bottle_1_5.info.QQid),
          id: this.temp.bottle_1_5.info.id,
          msg: {
            text: (this.temp.bottle_1_5.data as any).text,
            img: ((this.temp.bottle_1_5.data as any).img == undefined || (this.temp.bottle_1_5.data as any).img.length == 0) ? [] : (this.temp.bottle_1_5.data as any).img,
          },
          stat: this.temp.bottle_1_5.info.stat,
          timeout: this.temp.bottle_1_5.info.time_Date_object.getTime()
        }
        let data = JSON.stringify(new_bottle.msg)
        this.axios({
          method: 'post',
          url: `${api}/addBottle`,
          data: {
            token: Storage.state.token,
            QQid: new_bottle.QQid,
            id: new_bottle.id,
            msg: data,
            stat: new_bottle.stat,
            timeout: new_bottle.timeout
          }
        }).then((res: any) => {
          res = res.data
          switch (res.code) {
            case 201:
              ElMessage({
                message: `添加错误，请稍后重试`,
                type: 'error',
                duration: 3000
              })

              this.state.AddBottleButton_1_5 = true
              break;
            case 202:
              ElMessage({
                message: `添加错误，请稍后重试。${res.data.error}`,
                type: 'error',
                duration: 3000
              })
              this.state.AddBottleButton_1_5 = true
              break;
            case 400:
            case 401:
            case 203:

              ElMessage({
                message: `登录过期，请重新登录`,
                type: 'error',
                duration: 3000
              })
              setTimeout(() => {
                this.$cookies.remove('token')
                this.$router.push({ name: 'login' })
              }, 3000)
              this.state.AddBottleButton_1_5 = true
              break;
            case 500:
              ElMessage({
                message: `数据库错误`,
                type: 'error',
                duration: 3000
              })
              this.state.AddBottleButton_1_5 = true
              break
            case 200:
              ElMessage({
                message: `添加成功`,
                type: 'success',
                duration: 3000
              })
              this.state.Result_1_5 = true
              this.state.AddBottleButton_1_5 = true
          }
        })
      }
    },
    test() {
      console.log(new Date(1666614485859).toDateString())
    }
  }
});/**200：成功请求
     * 201: 请求失败，无原因
     * 202: 请求失败，原因在data.error
     * 203: token过期
     * 400: 用户未注册
     * 401: 密码错误
     * 500: 数据库错误
     */
function add0(m: number) { return m < 10 ? '0' + m : m }
function ts2time(shijianchuo: number): string {
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(shijianchuo);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

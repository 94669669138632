import { createStore } from "vuex";

const Store = createStore({
    state() {
        return {
            token: '',

        }
    },
    getters: {
        isLogin: (state:any) => {
            return state.token.length > 0
        }
    },
    mutations: {
        logout(state:any) {
            state.token = ''

        },
        addlogininfo(state:any, {token}) {
            state.token = token;
        }
    }
})

export default Store